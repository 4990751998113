@import "globals";
#mm-song-version {
  header#mm-song-version-landing {
    .cover-image {
      height: 250px;
      width: 100%;
      @include mobile {
        height: 80px;
      }
    }
    hgroup {
      @include relative;
      background: #efefed;
      height: auto;
      padding-bottom: 2em;
      text-align: center;
      width: 100%;
      z-index: 2;
      @include mobile {
        background: #fff;
        height: auto;
        padding-bottom: 20px;
      }
      .contain {
        @include relative;
      }
      .song-version-icon {
        @include relative;
        background: #fff;
        border-radius: 50%;
        display: block;
        height: 80px;
        margin: 0 auto;
        top: 40px;
        width: 80px;
        @include mobile {
          background: #f8f8f8;
          top: 20px;
        }
      }
      h1 {
        color: $black;
        padding-top: 50px;
        width: 75%;
        margin: 0 auto;
        @include mobile {
          font-size: 1.25rem;
          line-height: 1.5rem;
          padding-top: 0;
          padding-bottom: 6px;
          width: 90%;
        }
      }
      .artist-link {
        font-family: Obibok;
        font-size: 0.8rem;
        @include mobile {
          font-size: 0.6rem;
        }
        em,
        a {
          @include lead(2.8, 1.6);
          @include mobile {
            @include lead(1.4, 1.1);
          }
        }
        em {
          color: #666;
        }
        a {
          color: $purple;
          text-decoration: none;
          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
      .badges {
        position: absolute;
        right: 0;
        top: 72px;
        @include mobile {
          @include relative;
          margin-top: 38px;
          padding-bottom: 6px;
          text-align: center;
        }
        ul {
          @include mobile {
            display: inline-block;
            overflow: hidden;
            width: auto;
          }
          li {
            @include relative;
            background: #fff;
            border-radius: 50%;
            float: left;
            height: 52px;
            margin-left: 15px;
            width: 52px;
            @include mobile {
              background: none;
              border-radius: 0;
              height: 15px;
              margin: 0 8px;
              overflow: hidden;
              width: auto;
            }
            .new-song-version-icon,
            .staff-pick-icon {
              padding-bottom: 5px;
              svg {
                height: 53px;
                width: 53px;
                @include mobile {
                  height: 13px;
                  width: 13px;
                  .icon-border {
                    display: block !important;
                  }
                }
                g {
                  fill: var(--green);
                  .icon-border {
                    display: none;
                  }
                }
              }
              @include mobile {
                float: left;
              }
            }
            .label {
              color: #b4b4b2;
              display: block;
              font-family: Obibok;
              font-size: 0.6rem;
              line-height: 10px;
              text-align: center;
              text-transform: uppercase;
              width: 52px;
              @include mobile {
                float: left;
                line-height: 13px;
                margin-left: 5px;
                text-align: left;
                width: auto;
              }
            }
          }
        }
      }
    }
  }
  .mm-song-versions-table {
    position: relative;
  }
  .alternatives .song-version-badges {
    display: none;
  }
  .actions {
    background: #f8f8f8;
    padding: 12px 0;
    text-align: center;
    @include mobile {
      background: #fff;
      padding: 0;
      width: 100%;
    }
    ol {
      @include relative;
      display: inline-block;
      @include mobile {
        display: block;
        margin: 0 15px;
      }
      &.break {
        margin-left: -2px;
        @include mobile {
          @include box-sizing;
          margin-left: 12px;
          margin-right: 12px;
          overflow: hidden;
        }
      }
      li.tooltip {
        @include opacity(0);
        background: rgba(0, 0, 0, 0.8);
        border: 0;
        border-radius: 7px;
        box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.3);
        cursor: auto;
        height: 58px;
        margin: 0;
        position: absolute;
        top: -6px;
        z-index: -1;
        &.partial-exclusivity-tooltip {
          width: 240px;
          &[data-connect-action="download"] {
            left: -246px;
            &.nudge {
              left: -182px;
            }
          }
          &[data-connect-action="project-search"] {
            left: -120px;
            &.nudge {
              left: -180px;
            }
            &.de-nudge {
              left: -60px;
            }
          }
          &[data-connect-action="license"] {
            left: -246px;
            &.nudge {
              left: -182px;
            }
          }
          .tri {
            filter: drop-shadow(2px 3px 1px rgba(0, 0, 0, 0.2));
            left: unset;
            right: -8px;
            -moz-transform: none;
            -webkit-transform: none;
            -o-transform: none;
            -ms-transform: none;
            transform: none;
          }
        }
        &:active {
          background: rgba(0, 0, 0, 0.8);
        }
        &.show {
          @include opacity(1);
          z-index: 2;
        }
        @include tablet {
          width: 280px;
        }
        @include mobile {
          border-radius: 0;
          box-shadow: none;
          height: 62px;
          padding-right: 1px;
          top: -15px;
        }
        .tri {
          color: rgba(0, 0, 0, 0.8);
          font-size: 23px;
          left: -8px;
          line-height: 42px;
          pointer-events: none;
          position: absolute;
          text-decoration: none;
          top: 9px;
          -moz-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg);
          @include mobile {
            filter: none;
            top: 43px;
            left: 49%;
            -moz-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        }
        p {
          @include kern(80);
          color: #fff;
          font-family: NeuzeitGro, georgia;
          font-size: 13px;
          font-weight: bold;
          letter-spacing: 0.05rem;
          line-height: 15px;
          padding: 8px 10px 0;
          position: relative;
          text-align: left;
          text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
          text-transform: uppercase;
          white-space: normal;
          @include tablet {
            padding: 8px 30px 0 15px;
          }
          @include mobile {
            margin: 0;
            padding: 10px 0 0 15px;
            width: 85%;
          }
        }
      }
      li {
        @include relative;
        background: #fff;
        cursor: pointer;
        float: left;
        height: 47px;
        margin: 0 1px;
        width: 62px;
        @include transition(background, 100ms);
        @include mobile {
          float: none;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 99%;
          border: 2px solid #999;
          margin: 0 0 7px 0;
          height: 43px;
          text-align: center;
          @include transition(none);
        }
        &.play,
        &.favorite,
        &.mixtape,
        &.blanket-deal,
        &.download,
        &.customize,
        &.project-search,
        &.share,
        &.lyrics {
          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            svg {
              height: 45px;
              width: 45px;
              g {
                fill: $black;
                @include transition(fill, 100ms);
                .icon-border {
                  display: none;
                }
              }
            }
            @include mobile {
              display: none;
            }
          }
          &:hover {
            svg g {
              fill: #fff;
            }
          }
        }

        &.download {
          @include mobile {
            a {
              &:first-child {
                display: none;
              }
            }
          }
          @include not-mobile {
            a {
              &:last-child {
                display: none;
              }
            }
          }
        }
        &.disabled {
          @include opacity(0.5);
        }
        a {
          display: block;
          height: 100%;
        }
        &.half {
          @include mobile {
            @include box-sizing;
            width: 99%;
            margin: 0 2px 7px;
          }
        }
        &.license {
          &:hover,
          &:focus {
            .button-text {
              color: #fff;
            }
          }
          .button-text {
            font-family: Obibok;
            font-size: 0.75rem;
            text-align: center;
            line-height: 1rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: 100;
            text-wrap: wrap;
            @include mobile {
              display: none;
            }
          }
        }
        &.favorite {
          .icon {
            &.selected {
              svg g {
                fill: #21928e;
                @include transition(fill, 100ms);
              }
            }
          }
        }
        > div,
        > a {
          display: inline-block;
          @include mobile {
            padding-left: 15px;
          }
        }
        .label {
          display: none;
          @include mobile {
            font-family: Obibok;
            color: $black;
            display: block;
            float: left;
            font-size: 0.6rem;
            line-height: 45px;
            text-transform: uppercase;
          }
        }
        .icon {
          display: block;
          @include mobile {
            float: left;
          }
          span {
            @include transition(opacity, 100ms);
            @include mobile {
              @include transition(none);
            }
          }
          .default {
            @include opacity(1);
          }
          .over {
            @include opacity(0);
          }
        }
        &:hover:not(.tooltip),
        &:focus:not(.tooltip) {
          background: $black;
          @include mobile {
            background: #fff;
          }
          .icon {
            .default {
              @include opacity(0);
              @include mobile {
                @include opacity(1);
              }
            }
            .over {
              @include opacity(1);
              @include mobile {
                @include opacity(0);
              }
            }
          }
        }
        &:active {
          @include transition(background, 0ms);
          background: $purple;
          @include mobile {
            .icon {
              .default {
                @include opacity(0);
              }
              .over {
                @include opacity(1);
              }
            }
            .label {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .download-options-menu {
    background: #e7e7e7;
    box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.3);
    height: auto;
    width: auto;
    overflow: visible;
    position: absolute;
    left: 50%;
    visibility: hidden;
    margin-top: 20px;
    z-index: -99999;
    @include mobile {
      display: none;
    }
    &.expanded {
      visibility: visible;
      z-index: 15;
    }
    .action {
      width: auto;
      margin: 0;
      padding: 2px 10px;

      background: #fff;
      @include transition(background, 100ms);
      a {
        display: flex;
        text-decoration: none;
        align-items: center;
        color: black;
        .icon {
          width: 45px;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
        }
        .label {
          width: 85px;
          display: flex;
          align-items: flex-end;
          font-size: 0.75rem;
          padding-left: 10px;
          font-weight: 100;
          text-align: left;
        }
      }

      svg {
        height: 45px;
        width: 45px;
        g {
          fill: $black;
          @include transition(fill, 100ms);
          .icon-border {
            display: none;
          }
        }
      }

      &.disabled {
        @include opacity(0.5);
        pointer-events: none;
      }

      &:hover {
        background: $black;
        .label {
          color: #fff;
        }
        svg g {
          fill: #fff;
        }
        .default {
          @include opacity(0);
        }
        .over {
          @include opacity(1);
        }
      }
    }
  }

  .details {
    background: #fff;
    overflow: hidden;
    padding-bottom: 35px;
    padding-top: 80px;
    @include contain-break;
    @include mobile {
      margin: 0 15px;
      overflow: visible;
      padding: 25px 0;
      width: auto;
    }
    @include tablet {
      width: auto;
      padding: 20px;
    }
    .left,
    .right {
      width: 48.1%;
      @include mobile {
        width: 100%;
      }
      @include tablet {
        width: 50%;
      }
    }
    .left {
      float: left;
      @include mobile {
        clear: both;
        float: none;
      }
      p {
        font-family: Obibok;
        font-size: 0.9rem;
        font-weight: 100;
        line-height: 1.25rem;
        color: #666;
        padding-top: 10px;
        padding-bottom: 35px;
        @include mobile {
          @include lead(1.6, 1.1);
          font-size: 1.1em;
          padding-bottom: 20px;
          padding-top: 0;
        }
        a {
          color: $purple;
          text-decoration: none;
          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
        a ~ a::before {
          content: ", ";
        }
      }
      .song-version-waveform {
        max-width: 350px;
        padding-top: 10px;
        @include mobile {
          padding-bottom: 20px;
          padding-top: 0;
        }
      }
    }
    .right {
      float: right;
      @include mobile {
        clear: both;
        float: none;
      }
      dl {
        div {
          overflow: hidden;
          &:nth-child(odd) {
            background: #f8f8f8;
          }
        }
        dt,
        dd {
          @include lead(4, 1.8);
          color: $black;
          font-family: Obibok;
          font-size: 0.9rem;
          font-weight: 100;
          line-height: 2.45rem;
          @include mobile {
            font-size: 0.8rem;
            line-height: 2.25rem;
          }
        }
        dt {
          float: left;
          margin-left: 55px;
          @include mobile {
            margin-left: 20px;
          }
        }
        dd {
          float: right;
          margin-right: 55px;
          @include mobile {
            margin-right: 20px;
          }
          a {
            color: $purple;
            text-decoration: none;
            &:hover,
            &:focus {
              text-decoration: underline;
            }
          }
          a ~ a::before {
            content: ", ";
          }
        }
        .exclusivities {
          display: flex;
          justify-content: space-between;
          dd {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .pro-info-button {
        float: right;
        padding: 5px 25px;
        margin-top: 5px;
        font-family: Obibok;
        font-size: 0.9rem;
        font-weight: 100;
        line-height: 2.45rem;
        background-color: white;
        border: 3px solid var(--purple);
        color: var(--purple);
        &:hover {
          background-color: var(--purple);
          color: white;
          transition: background-color 0.1s ease-in-out;
          transition: color 0.1s linear;
        }

        @include mobile {
          display: none;
        }
      }
    }
  }
  .artist-details {
    @include relative;
    background: #f8f8f8;
    padding: 30px;
    margin: 0 auto;
    max-width: 1140px;
    width: 100%;
    @include contain-break;
    @include mobile {
      padding: 0;
      margin: 0 15px;
      width: auto;
    }
    @include tablet {
      padding: 20px;
      width: auto;
    }
    figure {
      background: #fff;
      overflow: hidden;
      width: 100%;
      @include mobile {
        overflow: visible;
      }
      img {
        float: left;
        width: 50%;
        @include mobile {
          float: none;
          width: 100%;
        }
        @include tablet {
          width: 50%;
        }
      }
      figcaption {
        display: block;
        float: right;
        width: 50%;
        @include mobile {
          float: none;
          width: 100%;
        }
        @include tablet {
          width: 50%;
        }
        div {
          margin: 55px;
          @include mobile {
            margin: 15px 0 25px;
          }
          @include tablet {
            margin: 20px;
          }
        }
        p {
          @include lead(2.1, 1.8);
          font-family: NeuzeitGro, georgia;
          font-size: 1.8em;
          color: #666;
          padding: 10px 0 20px 0;
          @include mobile {
            font-size: 1.1em;
          }
        }
        .box-button-black {
          font-family: Obibok;
          font-size: 0.75rem;
          font-weight: normal;
          letter-spacing: 0.1rem;
          display: block;
          height: 40px;
          line-height: 40px;
          width: 100%;
          @include mobile {
            font-size: 1.1em;
            height: 50px;
            line-height: 50px;
          }
        }
      }
    }
  }
  .alternatives {
    background: #fff;
    padding-bottom: 80px;
    @include mobile {
      padding: 0 0 25px 0;
    }
    h5 {
      @include contain-break;
      @include mobile {
        color: $black;
        font-size: 1.6em;
        padding-bottom: 10px;
        text-align: center;
      }
    }
  }
  .related {
    background: #fff;
    padding-bottom: 90px;
    padding-top: 80px;
    @include mobile {
      padding: 0 0 25px 0;
    }
    h5 {
      @include contain-break;
      @include mobile {
        color: $black;
        font-size: 1.6em;
        padding-bottom: 10px;
        text-align: center;
      }
    }
  }
  > article {
    background-color: white;
  }

  div #mm-select-blanket-order-modal {
    &.hidden {
      display: none;
    }
    div {
      text-align: left;
    }
    li {
      height: 21px;
      width: 100%;
      &:hover:not(.tooltip),
      &:focus:not(.tooltip),
      &:active {
        background: none;
      }
    }
  }
}
