@import "globals";

#mm-youtube-tracks {
  header#mm-youtube-tracks-landing {
    height: 7.5rem;
    width: 100%;
    @include touch {
      height: 80px;
    }
    @include mobile {
      height: 80px;
    }
  }

  .search-wrap {
    background-color: #eee;

    nav.tabs {
      &.mobile {
        height: 0px;
        @include mobile {
          height: 44px;
          width: 100%;
        }
      }

      &.desktop {
        height: 50px;
        width: 100%;
        @include mobile {
          height: 0px;
        }
        li {
          line-height: 50px;
        }
      }

      ul {
        height: 100%;
        overflow: hidden;
        width: 100%;
      }

      li {
        background: #fff;
        display: block;
        float: left;
        height: 100%;
        width: 50%;

        @include transition(background, 100ms);
        @include mobile {
          line-height: 44px;
        }

        a {
          @include kern(80);
          font-family: Obibok;
          font-size: 0.7rem;
          font-weight: 700;
          color: $black;
          display: block;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          width: 100%;

          @include mobile {
            font-size: 1.1em;
          }

          span.new::before {
            content: "NEW";
            font-family: NeuzeitGro, georgia;
            font-size: 11.2px;
            background-color: #21928e;
            border-radius: 100px;
            color: #fff;
            font-weight: 600;
            line-height: 22px;
            text-align: center;
            text-decoration: none;
            width: 45px;
            padding: 4px 6px 3px 7px;
            margin-right: 7px;
            border: 2px solid #eee;
          }
        }

        &:hover,
        &:focus {
          background: #f8f8f8;
        }

        &.current {
          background: $green;
          a {
            color: #fff;
          }
        }
      }

      .filter {
        a {
          float: right;
        }
      }
    }

    h3.youtube-search-header {
      width: 100%;
      text-align: center;
      font-family: NeuzeitGro, georgia;
      font-size: 4em;
      margin-top: 20px;
      color: var(--lighter-black);
    }

    article.similarity-filters-container {
      margin: 0 auto;
      width: 85%;
      max-width: 1140px;
      height: 200px;
      padding-bottom: 40px;
      overflow: hidden; /* Hide content when height is 0 */
      transition: height 0.3s ease-out, padding-bottom 0.3s ease-out, opacity 0.3s ease-out; /* Transition for smooth slide down */
      @include contain-break;
      @include mobile {
        background: #eee;
        display: block;
        height: auto;
        padding: 0;
        width: 100%;
      }

      &.closed {
        padding-bottom: 30px;
        height: 0;
        padding-bottom: 0;
        opacity: 0; /* Hide content */
        transition: height 0.3s ease-out, padding-bottom 0.3s ease-out, opacity 0.3s ease; /* Transition for smooth slide up */
      }

      .remove svg {
        height: 20px;
        width: 20px;

        g {
          fill: #fff;
        }
      }
    }

    form#search {
      margin: 0 auto;
      margin-top: 50px;
      width: 85%;
      max-width: 1140px;
      display: flex;
      align-items: center;

      #mm-search-input {
        background-color: #fff;
        margin-bottom: 50px;
        width: 90%;
        height: 60px;
        border-radius: 40px;
        position: relative;

        &.error {
          border: var(--red) solid 3px;
        }

        @include mobile {
          height: 40px;
        }

        .search-filter {
          background: #fff;
          border-radius: 0 40px 40px 0;
          color: #3a3532;
          cursor: pointer;
          display: inline-block;
          font-size: 2em;
          height: 60px;
          text-align: left;
          width: 14%;

          .expand.icon {
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 9px solid $black;
            right: 1%;
            top: 30px;
            position: absolute;

            @include small-display {
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-top: 8px solid $black;
            }
            @include tablet {
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 7px solid $black;
            }
            @include mobile {
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 6px solid $black;
              top: 48px;
            }
          }

          .target {
            font-family: NeuzeitGro, georgia;
            height: 60px;
            line-height: 65px;
            position: absolute;
            right: 5%;
            text-transform: capitalize;
            white-space: nowrap;
          }

          .menu {
            background: #fff;
            border-radius: 10px;
            border-top: 1px solid #eee;
            -webkit-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
            box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
            display: none;
            font-size: 80%;
            position: absolute;
            width: inherit;
            top: 100%;
            z-index: 100;

            .item:first-of-type {
              padding-top: 12px;
            }

            .item:first-of-type:hover {
              border-radius: 10px 10px 0px 0px;
            }

            .item:last-of-type:hover {
              border-radius: 0px 0px 10px 10px;
            }

            .item {
              font-family: NeuzeitGro, georgia;
              padding-bottom: 10px;
              padding-left: 15%;
              padding-top: 10px;
              text-transform: capitalize;

              @include small-display {
                padding-left: 10%;
              }

              &:hover,
              &:focus {
                background: $purple;
                color: #fff;
              }

              &.selected {
                font-weight: bold;
              }
            }
          }
          .visible {
            display: block;
          }
        }

        .search-input-group {
          display: inline-block;
          float: left;
          position: relative;
          width: 86%;

          @include mobile {
            width: 80%;
          }

          .icon {
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: -5px;
            position: absolute;

            svg {
              height: 60px;
              width: 60px;
              g {
                fill: #745bb4;
                .icon-border {
                  display: none;
                }
              }
            }
          }

          label {
            text-indent: -9999px;
            font-size: 0.1em;
            margin: 25px 0 0 10px;
            position: absolute;
          }

          input {
            font-family: NeuzeitGro, georgia;
            font-size: 3em;
            border: none;
            height: 60px;
            margin: 0 5% 0 50px;
            outline: none;
            width: inherit;

            @include mobile {
              font-size: 2em;
              height: 40px;
            }
          }
        }
      }
      button.youtube-submit {
        width: 100px;
        height: 50px;
        font-size: 1rem;
        margin-left: 20px;
        background-color: $purple;
        border: $purple solid 3px;
        color: white;
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        margin-bottom: 50px;
        border-radius: 50px;
        font-family: "Obibok";

        &:hover {
          background-color: white;
          color: $purple;
        }

        @include mobile {
          width: 100px;
          height: 40px;
          font-size: 1rem;
        }
      }
    }

    form.filters {
      height: 100%;
      left: 0;
      position: relative;
      top: 0;
      width: 100%;
      z-index: 2;
    }

    fieldset {
      display: none;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      @include mobile {
        @include relative;
        display: none;
        padding-bottom: 25px;
      }

      ul {
        height: 100%;
        width: 100%;
      }

      li {
        @include relative;
        display: block;
        float: left;
        height: 100%;
        margin-right: 6px;
        margin-bottom: 6px;

        .remove {
          @include opacity(0);
          cursor: pointer;
          display: block;
          position: absolute;
          right: 15px;
          top: 15px;
          z-index: 10;

          @include transition(opacity, 100ms);
          @include mobile {
            @include transition(none);
          }
        }

        &.no {
          @include mobile {
            float: right;
          }
        }

        a {
          font-family: Obibok;
          font-size: 1.2rem;
          font-weight: 100;
          background: #fff;
          border: 2px solid #fff;
          color: $black;
          display: block;
          height: 100%;
          text-align: center;
          text-decoration: none;
          width: 100%;
          @include transition(background color border-color, 100ms);

          .icon {
            transform: translateY(0px);
            @include transition(transform, 100ms);
          }

          @include mobile {
            @include transition(none);
            font-size: 0.9rem;
          }

          @include not-mobile {
            &:hover,
            &:focus {
              background: $purple;
              border-color: $purple;
              color: #fff;

              .icon svg g {
                fill: #fff;
              }

              .icon {
                transform: translateY(-10px);
              }
            }
          }

          .icon svg g {
            fill: $black;
          }

          figure {
            display: flex;
            align-items: center;
            flex-direction: column;
            height: 100%;
            justify-content: flex-end;

            figcaption {
              padding: 5px 0 15px;

              @include mobile {
                padding: 10px 0 35px;
              }
            }
          }
        }

        &.selected a {
          background: #fff;
          border-color: $purple;
          color: $purple;

          .icon svg g {
            fill: $purple;
          }
        }

        &.selected a:hover,
        &.selected a:focus {
          background: $purple;
          color: #fff;

          .icon svg g {
            fill: #fff;
          }
        }

        &.selected-negative a {
          border-color: rgba(58, 53, 50, 0.5);
          color: rgba(58, 53, 50, 0.5);
          text-decoration: line-through;
          @include not-mobile {
            &:hover {
              background: rgba(58, 53, 50, 0.5);
              border-color: rgba(58, 53, 50, 0);
              color: #fff;
              text-decoration: line-through;
              text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
            }
          }
          @include mobile {
            background: rgba(58, 53, 50, 0.5);
            border-color: rgba(58, 53, 50, 0);
            color: #fff;
            text-decoration: line-through;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
          }

          .default {
            filter: invert(60%);
          }
        }

        @include mobile {
          &.selected {
            a {
              background: $purple;
              color: #fff;

              .remove {
                @include opacity(1);
                display: block;
                padding-right: 2px;
                top: 6px;
                right: 0;
              }

              .icon svg g {
                fill: #fff;
              }
            }
          }
        }
      }

      li.actionable {
        @include no-select;
        left: 0;
        position: relative;
        top: 0;
        display: block;
        float: left;
        height: 100%;
        margin-right: 6px;
        margin-bottom: 6px;

        &:hover,
        &:focus {
          a {
            .count {
              color: var(--lighter-grey);
            }
          }
        }

        figcaption {
          .count {
            position: relative;
            top: -3px;
          }
        }
      }

      .true,
      .female,
      .male {
        figure .icon {
          svg {
            height: 100%;
            width: 60px;
          }

          @include mobile {
            svg {
              width: 35px;
            }
          }
        }
      }

      .false figure .icon {
        svg {
          height: 100px;
          width: 100px;
        }

        @include mobile {
          svg {
            height: 50px;
            width: 50px;
          }
        }
      }

      &.current {
        display: block;
        z-index: 2;
        @include mobile {
          display: block;
        }
      }

      &#voice {
        li {
          width: 25%;
          @include calc(width, "25% - 6px");
          @include mobile {
            @include fluid-square;
            @include calc(width, "25% - 6px");
            padding-bottom: 17em;
          }
        }
      }
    }
  }

  #empty-search-wrapper {
    box-sizing: border-box;
    padding: 50px 1em 100px 1em;
    text-align: center;
    height: 100%;
    margin: 0 auto;
    width: 100%;
    .empty-search-text {
      color: #3a3532;
      padding-bottom: 15px;
    }
    p.paragraph {
      font-family: Obibok;
      font-size: 1rem;
      font-weight: 100;
      letter-spacing: 0.1rem;
      line-height: 30px;
    }
  }

  .long-process-wrapper {
    .long-process-text {
      display: none;
    }
    .long-process-message {
      text-align: center;
      position: relative;
      bottom: 30px;
      &.failed {
        margin-top: 80px;
        color: var(--red);
      }
    }
  }

  .spotify-message {
    text-align: center;
    bottom: 1rem;
    margin-top: 2rem;
    .failed {
      font-size: 1rem;
      color: var(--red);
    }
  }

  .youtube-player-wrapper {
    margin: 20px auto;
    background: #000;
    border: 4px solid #000;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    width: 600px;
    border-radius: 12px;

    @media screen and (max-width: 767px) {
      font-size: 0.75rem;
      width: 200px;
    }
  }
  .youtube-iframe-wrapper {
    height: 0;
    position: relative;
    padding-top: 56.25%; /* for responsive 16:9 aspect ratio */
    width: 100%;
  }

  iframe.youtube-similarity-player {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    border-radius: 10px;
  }

  #mm-song-versions {
    max-width: 1140px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}
